<template>
  <div class="loan_item">
    <div class="inner inner1">
      <h1>SOBRE NOSOTROS</h1>
    </div>
    <div class="inner inner2">
      <!-- <h3>Por qué elegir Mipréstamo</h3> -->
      <div class="flex fd-c">
        <div v-for="(item,index) in inner_4items" :key="index" class="content flex ai-c jc-sb" :class="index === 1 ?'fd-r jc-sb':''">
          <img :src="item.img">
          <div class="content2 flex fd-c jc-sb">
            <h1>{{ item.title }}</h1>
            <div v-for="(item2,index2) in item.list2" :key="index2">
              <p v-if="item2.txt">{{ item2.txt }}</p>
              <p v-if="item2.txt2">{{ item2.txt2 }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <div class="title">
        <h3>Comentarios de nuestros clients</h3>
      </div>
      <div class="flex">
        <div v-for="(item,index) in inner_3items" :key="index" class="content">
          <div class="flex fd-c ai-c jc-c">
            <img :src="item.img">
            <h1>{{ item.name }}</h1>
            <h2>{{ item.txt }}</h2>
            <p>{{ item.txt2 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner5">
      <div class="title">
        <h3>Contáctanos</h3>
      </div>
      <div class="flex items">
        <div v-for="(item,index) in inner5items" :key="index" class="content">
          <div class="flex fd-c ai-c">
            <img :src="item.img">
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner_3items: [
        { img: require('@/assets/images/people1.png'), name: 'Roldan Mascorro', txt: 'Autónomos', txt2: '“Muy bueno, obtuve un pequeño préstamo de \n $ 3,000 y fue muy rápido así que lo califico muy \n bien y seguiré usando este servicio, fue genial \n y le di 5 estrellas, muchas gracias por su \n bendición.  “' },
        { img: require('@/assets/images/people2.png'), name: 'Luiza Sedillo', txt: 'Empleados', txt2: '“Me ha gustado porque ha sido rápido y fácil. \n La forma de solicitar el préstamo y los pasos \n posteriores fueron rápidos y claros. Estoy \n muy contento con el proceso, muy contento.”' },
        { img: require('@/assets/images/people3.png'), name: 'Cristiano Canal', txt: 'Pintor', txt2: '“BonoUp es una excelente institución financiera \n y son muy flexibles y convenientes para sus \n clientes en comparación con otras aplicaciones. \n Estoy muy contento y agradecido por su ayuda, \n gracias！”' },
        { img: require('@/assets/images/people4.png'), name: 'Alhertina Rayon', txt: 'Empresarios', txt2: '“Me gusta BilletePlus porque es muy rápido en \n darme el dinero que necesito y si tienes alguna \n duda se comunican inmediatamente. Por \n supuesto, lo recomiendo.”' }
      ],
      inner_4items: [
        { img: require('@/assets/images/inner_4_5.png'), title: 'Perfil de la empresa', list2: [{ txt: 'BilletePlus es una plataforma líder en línea que proporciona pequeños préstamos \n \n a consumidores que necesitan acceso rápido y fácil a efectivo. Nuestra misión \n es ayudar a nuestros clientes a alcanzar sus metas financieras al proporcionar \n préstamos rápidos, convenientes y seguros que se pueden acceder en línea o \n a través de nuestra aplicación móvil.En BilletePlus, nos enorgullece nuestro \n compromiso con la satisfacción del cliente. Entendemos que nuestros clientes \n buscan un prestamista confiable y de confianza, y hacemos todo lo posible para \n satisfacer sus necesidades. Nuestro proceso de solicitud es rápido y fácil, y \n nuestro equipo de soporte al cliente siempre está disponible para responder \n cualquier pregunta o inquietud.', txt2: ' \n También ofrecemos términos flexibles de pago y tasas de interés competitivas, \n lo que hace que sea fácil para nuestros clientes administrar sus finanzas y \n pagar sus préstamos a tiempo. Con BilletePlus, puede estar seguro de que está \n trabajando con un prestamista confiable y responsable.' }] }
        // { img: require('@/assets/images/inner_4_4.png'), title: 'Visión', list2: [{ txt: 'Con el fin de resolver el problema del uso de los fondos y mejorar la calidad de vida de todos, nos comprometemos a proporcionar los mejores servicios de tecnología financiera a la sociedad.' }] }
        // { img: require('@/assets/images/inner_4_3.png'), title: 'Préstamo rápido', list2: [{ txt2: 'Una vez aprobado el sistema, el dinero se transferirá \n directamente a su tarjeta bancaria.' }] }
      ],
      inner6ItemLeftList: [
        { title: 'E-mail:pesogoviphelp@outlook.com', img: require('@/assets/images/email.png') },
        { title: 'Address:170 TORRE VENEZIA SUITES TIMOG AVE. COR. SCT. SANTIAGO ST. LAGING HANDA', img: require('@/assets/images/address.png') },
        { title: 'Service Hours : 9:00 - 17:00', img: require('@/assets/images/time.png') },
        { title: 'SEC Registration No.CS201911897;CERTIFICATE OF AUTHORITY NO.3037' }
      ],
      inner5items: [
        { img: require('@/assets/images/people5.png'), txt: 'E-mail:', txt2: 'bonoupapp@gmail.com' },
        { img: require('@/assets/images/people6.png'), txt: 'Dirección de la oficina:', txt2: 'Villa Jardín 12,Atizapán de Zaragoza,MEX,52923,Mexico' },
        { img: require('@/assets/images/people7.png'), txt: 'Tiempo laboral：', txt2: 'De lunes a viernes 9:00-16:00' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    margin: 0 auto;
    overflow: hidden;
    padding: 50px calc((100% - 1100px) / 2) ;
    h3{
      font-size: 18px;
    }
    .content{
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #6E60F9;
      }
    }
  }
  .inner1{
    background: url('~@/assets/images/loanImg/abouts_bg.png') no-repeat;
    background-size: 100% 100%;
    padding: 140px calc((100% - 1100px) / 2);
    p{
      font-size: 15px;
      font-family: Arial-Regular, Arial;
      font-weight: 400;
      color: #FFFFFF;
      width:calc(38%);
      line-height: 30px;
    }
    h1{
      font-size: 50px;
      color: white;
    }
  }
  .inner2{
    position: relative;
    padding:90px calc((100% - 1100px) / 2) 60px;
      // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    h3{
      font-size: 30px;
      color: black;
      margin-bottom: 60px;
      text-align: center;
    }
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      width: 100%;
      text-align: left;
      color:white;
      margin-bottom: 60px;
      // background: #F1F5FF;
      h1{
        font-size: 30px;
        margin-bottom: 30px;
        // margin:0 0 50px 0;
        position: relative;
      }
      p{
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        // width: 70%;
        position: relative;
        // &::before{
        //   position: absolute;
        //   display: block;
        //   content:'';
        //   width: 10px;
        //   height: 10px;
        //   border-radius: 5px;
        //   background: #0071D6;
        //   margin:0 10px 5px 0;
        //   left: -28px;
        //   top: 7px;
        // }
      }
      h2{
        color: #666666;
        text-align: left;
        font-size: 15px;
        margin-bottom: 20px;
        font-weight: 100 !important;
        line-height: 23px;
      }
      img{
        width: 470px;
      }
    }
    .content2{
      width: 49%;
      text-align: left;
      color: black;
      margin-left: 50px;
      justify-content: center;
      p{
        color: #333333;
        font-weight: 100 !important;
        line-height: 23px;
      }
    }
  }
  .inner3{
    position: relative;
    padding:100px calc((100% - 1200px) / 2) ;
    background: #F8F9FA;
    .title{
      margin-bottom: 50px;
      h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin: 20px auto 0;
        // }
      }
    }
    .content{
      width: 35%;
      margin: 0 10px;
      padding-bottom: 20px;
      color: black;
      background: white;
      border-radius: 20px;
      div{
        height: 100%;
      }
      .fd-c{
        padding: 20px 0 10px;
      }
      img{
        width: 30%;
        border-radius: 20px 20px 0 0;
      }
      h1{
        font-size: 15px;
        line-height: 40px;
      }
      h2{
        font-size: 12px;
        color: #999999;
        margin-bottom: 10px;
      }
      p{
        color: #666666;
        font-size: 12px;
        line-height: 20px;
        padding: 0px 24px  0px;
        height: 40%;
      }
    }
  }
  .inner5{
    position: relative;
    padding:100px calc((100% - 1200px) / 2) ;
    background: #063EA4;
    .title{
      text-align: center;
      margin-bottom: 50px;
      p{
        color: #666666;
        font-size: 16px;
        margin: 0;
      }
      h3{
        font-size: 30px;
        color: white;
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin: 20px auto 0;
        // }
      }
    }
    .content{
      width: 33%;
      margin: 0 10px;
      padding-bottom: 20px;
      text-align: center;
      color: white;
      border-radius: 20px;
      div{
        height: 100%;
      }
      img{
        width: 40%;
        border-radius: 20px 20px 0 0;
      }
      h1{
        margin-top: 20px;
        font-size: 18px;
        line-height: 50px;
      }
      p{
        color: #FFFFFF;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        padding: 0px 24px  0px;
      }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    .inner{
      min-width: calc(100vw) !important;
    }
    .inner1{
    background: #0049A3;
    // background-size: 100% 100%;
    color: white;
    padding: 50px 20px;
    h1{
      font-size: 30px;
      width:100%;
      text-align: center;
      line-height: 30px;
    }
  }
    .inner2{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw) !important;
      margin: 0 auto !important;
      padding: 0;
      div{
        width: 100%;
      }
      p{
        padding: 10px 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .content{
        flex-direction: column;
        margin-bottom: 20px;
        h1{
          font-size: 20px;
          margin: 20px 0;
        }
        img{
          margin: 0;
        }
        p{
          width: 80% !important;
          margin: 0 auto;
          font-size: 12px;
        }
      }
      .content2{
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center !important;
        padding: 0 !important;
        margin-left: 0;
        h2{
          margin: 0 20px;
          font-size: 12px;
          margin-bottom: 20px;
        }
      }
      .left_bg{
        width: 30px;
        height: 50px;
        top: 40px;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        .content{
          flex: 1;
          width: 100%;
          margin: 20px 0;
        }
      }
      .carousel-button{
        padding: 0 10px;
        img{
          width: 10px;
        }
      }
      .carousel-container{
        height: 600px;
      }
      .carousel-wrapper{
        padding: 0 20px;
        flex-wrap: wrap;
        .carousel-item{
          width: 45%;
          margin: 0 5px;
        }
      }
      .carousel-item{
        width: 150px;
        height: 280px;
        img{
          width: 60px;
        }
        h1{
          font-size: 13px;
          height: 40px;
          padding: 0;
          line-height: 16px;
        }
        p{
          font-size: 12px;
          height: 90px;
          padding: 0 5px;
          line-height: 18px;
        }
      }
    }
    .inner3{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw ) !important;
      margin: 0 auto !important;
      padding: 50px 0px;
      h3{
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .flex{
        flex-direction: column;
      }
      .content{
        width: calc(100% - 40px);
        margin-bottom: 50px;
        margin: 0 auto 40px;
        div{
          width: calc(100vw - 40px) !important;
          p{
            font-size: 12px;
          }
        }
      }
      .title{
        width: 100%;
        margin-bottom: 20px;
      }
      .inner5_item{
        display: flex;
        flex-direction: column;
      }
    }
    .inner5{
      .items{
        flex-direction: column;
        .content{
          width: 100%;
        }
      }
    }
  }
}
</style>
